import { graphql } from "gatsby"
import Layout from "./common/layout"
import Head from "./common/Head"
import Career from "./company/Career/Career"
import { useFormatter } from "helpers/i18n"

const CompanyCareer = ({ data }) => {
  const { currentLocale } = useFormatter()

  const allJobs = data?.allJob?.nodes
  return (
    <>
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Job opportunities at OnPoint, the leading e-commerce enabler"
            : "Cơ hội phát triển trong ngành thương mại điện tử tại OnPoint"
        }
        description={
          currentLocale === "en"
            ? "Join us in creating impact that matters in e-commerce. Shape the future and start your growth journey with us!"
            : "Tham gia OnPoint để cùng tạo ra sức ảnh hưởng trong ngành thương mại điện tử. Định hình tương lai và bắt đầu hành trình phát triển sự nghiệp với chúng tôi!"
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/company-2-meta.png"
        }
      />
      <Layout>
        <Career data={allJobs} />
      </Layout>
    </>
  )
}

export default CompanyCareer

export const query = graphql`
  query allJobQuery {
    allJob {
      nodes {
        seq
        is_tech
        name
        level
        deadline
        pathJob: gatsbyPath(filePath: "/jobs/{job.name}")
      }
    }
  }
`
